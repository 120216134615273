import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from "rxjs";
import {CveModel} from "../model/cve.model";
import {LocalStorageService} from "./local-storage.service";
import {catchError, map} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiURL = 'https://cve-api.virapayesh.ir';
  token: string;

  constructor(private http: HttpClient,
  ) {
    this.token = localStorage.getItem('token')

  }


  getSingleCVE(Token: string, doc_content_id: string) {
    console.log("getSingleCVE")
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/docs_contents/` + doc_content_id + '/', {headers: headers});
  }

  updateCVE(Token: string, doc_content_id: string, data: any) {
    console.log("updateCVE");
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.patch(`${this.apiURL}/v1/admin/docs_contents/` + doc_content_id + '/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  // confirm
  confirmCVE(Token: string, doc_content_id: string, data: any) {
    console.log("confirmCVE");

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.patch(`${this.apiURL}/v1/admin/docs_contents/` + doc_content_id + '/confirm', data, {
      headers: headers,
      observe: 'response'
    });
  }


  public getAllCWE(Token: string) {
    console.log("getAllCWE")
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/cwes/all`, {headers: headers, observe: 'response'});
  }


  // getAllBrand(Token: string) {
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.set("accept", "application/json, text/plain, */*");
  //   headers = headers.set("Authorization", "Bearer " + Token);
  //   return this.http.get(`${this.apiURL}/v1/admin/brands/all`, {headers: headers});
  // }

  getAllBrand(Token: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/vendors`, {headers: headers});
  }

  getAllBrandWithPagination(Token: string, params: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/vendors/?` + params, {headers: headers, observe: 'response'});
  }

  getAllProductWithPagination(Token: string, params: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/products?` + params, {headers: headers, observe: 'response'});
  }

  getAllCWEWithPagination(Token: string, params: any) {
    console.log("getAllCWEWithPagination")
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/cwes?` + params, {headers: headers, observe: 'response'});
  }

  getAllSource(Token: string) {
    console.log("getAllSource")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(` ${this.apiURL}/v1/admin/scores/distinct-source`, {headers: headers});
  }

  searchAdvance(Token: string, data: any) {
    console.log("searchAdvance");
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/docs_contents?` + data, {headers: headers});
  }

  searchAdvanceNextPrev(Token: string, path: string) {
    console.log("searchAdvanceNextPrev")

    console.log(path)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}` + path, {headers: headers});
  }

  searchAdvanceNextPrevTest(Token: string, params: any) {
    console.log("searchAdvanceNextPrevTest")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/docs_contents?` + params, {headers: headers});
  }

  getAllProduct(Token: string, data: any) {
    console.log("getAllProduct")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(` ${this.apiURL}/v1/admin/products/all?` + data, {headers: headers});
  }

  CveScoreReport(Token: string, data: any) {
    console.log("CveScoreReport")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/dashboard?` + data, {headers: headers, observe: 'response'});
  }


  monthly_statistics(Token: string,) {
    console.log("monthly_statistics")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/dashboard/monthly_statistics`, {headers: headers});
  }

  countBrandProductCWE(Token: string) {
    console.log("countBrandProductCWE")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/dashboard/db-statics`, {headers: headers});
  }

  searchTopBrands(Token: string, data: any) {
    console.log("searchTopBrands")

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/dashboard/brand-statics?` + data, {headers: headers});
  }

  // get single product
  single_product(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/products/` + data + '/', {headers: headers, observe: 'response'});
  }

  delete_product(product_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/products/` + product_id + '/', {
      headers: headers,
      observe: "response"
    });
  }

  // Email_subscription
  saveEmail_subscription(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.post(`${this.apiURL}/v1/admin/email_subscription`, data, {headers: headers});
  }

  listEmail_subscription(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/email_subscription?is_enable=true` + data, {headers: headers});
  }

  single_email_subscription(token: string, id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + token);
    return this.http.get(`${this.apiURL}/v1/admin/email_subscription/` + id + '/', {
      headers: headers,
      observe: 'response'
    });
  }

  update_email_subscription(id: any, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/email_subscription/` + id + '/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  delete_Email_subscription(email_subscription_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/email_subscription/` + email_subscription_id + '/', {
      headers: headers,
      observe: "response"
    })
        .pipe(map(data => {
          console.log("Here will be return response code Ex :200", data.status)
          return data.status
        }));
  }

  // SMS_subscription
  saveSMS_subscription(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.post(`${this.apiURL}/v1/admin/sms_subscription`, data, {headers: headers});
  }

  update_sms_subscription(id: any, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/sms_subscription/` + id + '/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  single_sms_subscription(token: string, id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + token);
    return this.http.get(`${this.apiURL}/v1/admin/sms_subscription/` + id + '/', {
      headers: headers,
      observe: 'response'
    });
  }

  listSMS_subscription(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/sms_subscription?is_enable=true` + data, {headers: headers});
  }

  delete_SMS_subscription(sms_subscription_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/sms_subscription/` + sms_subscription_id + '/', {
      headers: headers,
      observe: "response"
    })
        .pipe(map(data => {
          console.log("Here will be return response code Ex :200", data.status)
          return data.status
        }));
  }

  brands_new(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.post(`${this.apiURL}/vendor/`, data, {headers: headers, observe: 'response'})
        .pipe(
            catchError((error) => {
              return throwError(error.message);
            })
        );
  }


  update_brands(brand_id: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.put(`${this.apiURL}/vendors/` + brand_id + '?Vendor_ID=' + brand_id, data, {
      headers: headers,
      observe: 'response'
    });
  }

  delete_brands(brand_id: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/vendors/` + brand_id, {
      headers: headers,
      observe: 'response'
    });
  }

  product_new(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.post(`${this.apiURL}/v1/admin/products`, data, {headers: headers, observe: 'response'});
  }

  update_product(product_id: any, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/products/` + product_id + '/', data, {
      headers: headers,
      observe: 'response'
    });
  }

  cwe_new(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.post(`${this.apiURL}/v1/admin/cwes`, data, {headers: headers, observe: 'response'});
  }

  delete_cwe(cwe_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/cwes/` + cwe_id + '/', {
      headers: headers,
      observe: "response"
    });
  }

  update_cwe(cwe_id: any, data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/cwes/` + cwe_id + '/', data, {
      headers: headers,
      observe: "response"
    });
  }

  // سطوح دسترسی
  allGroups(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/groups`, {
      headers: headers,
      observe: "response"
    });
  }

  delete_Groups(group_name): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/groups/` + group_name + '/', {
      headers: headers,
      observe: "response"
    });
  }

  get_Groups(group_name): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/groups/` + group_name + '/', {
      headers: headers,
      observe: "response"
    });
  }

  get_All_Entities(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/entities/all`, {
      headers: headers,
      observe: "response"
    });
  }

  new_Groups(body): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.post(`${this.apiURL}/v1/admin/groups`, body, {
      headers: headers,
      observe: "response"
    });
  }

  update_Groups(group_name, body): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/groups/` + group_name + '/', body, {
      headers: headers,
      observe: "response"
    });
  }

  allUsers(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/users`, {
      headers: headers,
      observe: "response"
    });
  }

  nextItemUsers(nextUrl: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${nextUrl}`, {
      headers: headers,
      observe: "response"
    });
  }

  previousItemUsers(previousItemUsers: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${previousItemUsers}`, {
      headers: headers,
      observe: "response"
    });
  }


  info_user(user_id): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/users/` + user_id + '/', {
      headers: headers,
      observe: "response"
    });
  }

  new_users(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.post(`${this.apiURL}/v1/admin/users`, data, {
      headers: headers,
      observe: "response"
    });
  }

  update_user(user_id: any, data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/users/` + user_id + '/', data, {
      headers: headers,
      observe: "response"
    });
  }

  get_profile(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/me`, {
      headers: headers,
      observe: "response"
    });
  }

  update_profile(body): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/me`, body, {
      headers: headers,
      observe: "response"
    });
  }

  delete_users(user_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/users/` + user_id + '/', {
      headers: headers,
      observe: "response"
    });
  }

  // states
  insert_states(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.post(`${this.apiURL}/v1/admin/states`, data, {
      headers: headers,
      observe: "response"
    });
  }

  list_all_states(params): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/states?` + params, {
      headers: headers,
      observe: "response"
    });
  }

  get_states(state_id): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/states/` + state_id, {
      headers: headers,
      observe: "response"
    });
  }

  delete_states(state_id): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/states/` + state_id, {
      headers: headers,
      observe: "response"
    });
  }

  insert_city(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.post(`${this.apiURL}/v1/admin/cities`, data, {
      headers: headers,
      observe: "response"
    });
  }

  delete_city(city_id): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/cities/` + city_id, {
      headers: headers,
      observe: "response"
    });
  }

  list_city(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/cities?` + data, {
      headers: headers,
      observe: "response"
    });
  }

  list_city_of_state(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/cities?` + data, {
      headers: headers,
      observe: "response"
    });
  }

  // company
  insert_company(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.post(`${this.apiURL}/v1/admin/companies`, data, {
      headers: headers,
      observe: "response"
    });
  }

  delete_company(company_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/companies/` + company_id, {
      headers: headers,
      observe: "response"
    });
  }

  update_company(company_id: any, data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/companies/` + company_id, data, {
      headers: headers,
      observe: "response"
    });
  }

  get_company(company_id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/companies/` + company_id, {
      headers: headers,
      observe: "response"
    });
  }

  get_all_company(params): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/companies?` + params, {
      headers: headers,
      observe: "response"
    });
  }

  get_all_company_descendant(id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/companies/` + id + '/descendant', {
      headers: headers,
      observe: "response"
    });
  }

  get_company_cves(id: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.get(`${this.apiURL}/v1/admin/companies/` + id + '/cves', {
      headers: headers,
      observe: "response"
    });
  }

  // kashef api

  public getAllReportKashef(Token: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/kashef_reports?` + data, {headers: headers});
  }

  getSingleCVEKashef(Token: string, report_id: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + Token);
    return this.http.get(`${this.apiURL}/v1/admin/kashef_reports/` + report_id + '/', {headers: headers});
  }

  updateSingleKashefReport(kashef_report_id: any, body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/kashef_reports/` + kashef_report_id + '/revision', body, {
      headers: headers,
      observe: "response"
    });
  }


  bulkUpdateKashefReport(data: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.patch(`${this.apiURL}/v1/admin/kashef_reports`, data ,  {
      headers: headers,
      observe: "response"
    });
  }
  //  bulk delete kashef report
  bulk_delete_kashef_report(ids: string[]): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("accept", "application/json, text/plain, */*");
    headers = headers.set("Authorization", "Bearer " + this.token);
    return this.http.delete(`${this.apiURL}/v1/admin/kashef_reports`,  {
      headers: headers,
      body: { ids: ids }, // Include the data in the request body
      observe: 'response'
    });
  }
  // create kashef report

create_kashef_report(data: any): Observable<any> {
  let headers: HttpHeaders = new HttpHeaders();
headers = headers.set("accept", "application/json, text/plain, */*");
headers = headers.set("Authorization", "Bearer " + this.token);
return this.http.post(`${this.apiURL}/v1/admin/kashef_reports`, data, {
  headers: headers,
  observe: "response"
});
}

}
