import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import * as moment from 'jalali-moment';
import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePickerComponent, DatePickerDirective} from "ng2-jalali-date-picker";
import {Jalali} from 'jalali-ts';
import {LocalStorageService} from "../../../@core/Api/local-storage.service";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {SharedDataService} from "../../../@core/Api/dateHandler.service";
import {Router} from "@angular/router";

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const day = today.getDay();

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  events: string[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  @ViewChild('dateDirectivePicker') datePickerDirective: DatePickerDirective;
  @ViewChild('dayPicker') datePicker: DatePickerComponent;
  form: FormGroup;
  date: string;
  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, day)),
    end: new FormControl(new Date(year, month, day + 1)),
  });
  formDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  // themes = [
  //   {
  //     value: 'default',
  //     name: 'روشن',
  //   },
  //   {
  //     value: 'dark',
  //     name: 'تاریک',
  //   },
  //   {
  //     value: 'cosmic',
  //     name: 'کیهانی',
  //   },
  //   {
  //     value: 'corporate',
  //     name: 'شرکتی',
  //   },
  // ];

  currentTheme = 'default';

  userMenu = [{title: 'پروفایل', data: {id: 'profile'}}, {title: 'خروج ', data: {id: 'logout'}}];
  dateTime = new FormControl('');

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private localStorage: LocalStorageService,
              private fb: FormBuilder,
              private router: Router,
              public SharedDataService: SharedDataService,) {
  }

  ngOnInit() {
    // this.date = moment('1395-11-22','jYYYY,jMM,jDD');
    this.form = this.fb.group({
      date: new FormControl('', Validators.required),

    });
    this.currentTheme = this.themeService.currentTheme;
    if (this.localStorage.getCurrentUser() === true) {
      this.user = this.localStorage.userJson;
    }
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => {
    //     this.user = users.nick;
    //     console.log(users);
    //   })


    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    // this.menuService.onItemClick().subscribe((event) => {
    //   if (event.item.data.id === 'logout') {
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('brandList');
    //     localStorage.removeItem('cweList');
    //     this.router.navigate(['/']);
    //   }
    //   if (event.item.data.id === 'profile') {
    //     this.router.navigate(['/pages/profile-user']);
    //   }
    // });
  }

  toEnglishDigits(str) {
    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  }

  get currentUrl(): string {
    return this.router.url;
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    dateRangeStart.value = this.toEnglishDigits(dateRangeStart.value);
    dateRangeEnd.value = this.toEnglishDigits(dateRangeEnd.value);
    if (this.campaignOne.controls['start'].value !== null && this.campaignOne.controls['end'].value !== null) {
      let jalaliDateStart = moment(dateRangeStart.value).locale('en').format('YYYY/MM/DD');
      let jalaliDateEnd = moment(dateRangeEnd.value).locale('en').format('YYYY/MM/DD');
      const jalaliStart = Jalali.parse(jalaliDateStart);
      const jalaliEnd = Jalali.parse(jalaliDateEnd);
      let date = {
        from: jalaliStart.gregorian('YYYY-MM-DD hh:mm:ss'),
        to: jalaliEnd.gregorian('YYYY-MM-DD hh:mm:ss')
      }
      this.SharedDataService.changeMessageDate(date);
    }
  }

  searchData() {
    if (this.formDate.controls['start'].value !== null && this.formDate.controls['end'].value !== null) {
      const from = moment(this.formDate.controls['start'].value).locale('en').format('YYYY-MM-DD hh:mm:ss');
      const to = moment(this.formDate.controls['end'].value).locale('en').format('YYYY-MM-DD hh:mm:ss');


      let date = {
        from: from,
        to: to
      }
      this.SharedDataService.changeMessageDate(date);
    }
  }

  close() {
    this.datePickerDirective.api.open();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  logOut() {
    alert('exit')
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  ngAfterViewInit(){
    console.log('AfterViewInit');
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.data.id === 'logout') {
        localStorage.removeItem('token');
        localStorage.removeItem('brandList');
        localStorage.removeItem('cweList');
        this.router.navigate(['/']);
      }
      if (event.item.data.id === 'profile') {
        this.router.navigate(['/pages/profile-user']);
      }
    });
  }
}
