<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo"  (click)="navigateHome()">
      <img src="../../../../assets/images/logo.png" style="width: 98px;height: 98px"/>
      <span>  ویرا پایش  </span></a>
  </div>
  <div class="mr-5">
  </div>
</div>

<div class="header-container"  *ngIf="currentUrl === '/pages'">
  <form [formGroup]="formDate">
    <label>تاریخ شروع:</label>
    <dp-date-picker class="ml-1"
      mode="day"
      formControlName="start"
      placeholder="تاریخ شروع"
      theme="dp-material">
    </dp-date-picker>
    <label>تاریخ پایان:</label>
    <dp-date-picker
      dir="rtl"
      mode="day"
      formControlName="end"
      placeholder="تاریخ پایان"
      theme="dp-material">
    </dp-date-picker>
  </form>
<!--    <mat-form-field class="example-form-field" appearance="fill" dir="rtl">-->
<!--      <mat-date-range-input-->
<!--        [formGroup]="campaignOne"-->
<!--        [rangePicker]="campaignOnePicker"-->
<!--        [comparisonStart]="campaignOne.value.start"-->
<!--        [comparisonEnd]="campaignOne.value.end">-->
<!--        <input matStartDate placeholder="تاریخ شروع" formControlName="start"   #dateRangeStart>-->
<!--        <input matEndDate placeholder="تاریخ پایان" formControlName="end" #dateRangeEnd-->
<!--               (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">-->
<!--      </mat-date-range-input>-->
<!--&lt;!&ndash;      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>&ndash;&gt;-->
<!--&lt;!&ndash;      <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>&ndash;&gt;-->
<!--      <mat-date-range-picker touchUi  #campaignOnePicker disabled="false"></mat-date-range-picker>-->
<!--    </mat-form-field>-->

  <nb-actions size="small">
    <form [formGroup]="formDate">
      <label>تاریخ شروع:</label>
      <dp-date-picker class="ml-1"
                      mode="day"
                      formControlName="start"
                      placeholder="تاریخ شروع"
                      theme="dp-material">
      </dp-date-picker>
      <label>تاریخ پایان:</label>
      <dp-date-picker
        dir="rtl"
        mode="day"
        formControlName="end"
        placeholder="تاریخ پایان"
        theme="dp-material">
      </dp-date-picker>

      <!--    <mat-form-field class="example-form-field" appearance="fill" dir="rtl">-->
      <!--      <mat-date-range-input-->
      <!--        [formGroup]="campaignOne"-->
      <!--        [rangePicker]="campaignOnePicker"-->
      <!--        [comparisonStart]="campaignOne.value.start"-->
      <!--        [comparisonEnd]="campaignOne.value.end">-->
      <!--        <input matStartDate placeholder="تاریخ شروع" formControlName="start"   #dateRangeStart>-->
      <!--        <input matEndDate placeholder="تاریخ پایان" formControlName="end" #dateRangeEnd-->
      <!--               (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">-->
      <!--      </mat-date-range-input>-->
      <!--&lt;!&ndash;      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>&ndash;&gt;-->
      <!--&lt;!&ndash;      <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>&ndash;&gt;-->
      <!--      <mat-date-range-picker touchUi  #campaignOnePicker disabled="false"></mat-date-range-picker>-->
      <!--    </mat-form-field>-->
    </form>
<!--    <nb-action class="control-item" icon="calendar-outline" (click)="campaignOnePicker.open()"></nb-action>-->
    <nb-action class="control-item" icon="search-outline" (click)="searchData()"></nb-action>
<!--    <nb-action class="control-item" icon="email-outline"></nb-action>-->
<!--    <nb-action class="control-item" icon="bell-outline"></nb-action>-->

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.firstName"
               [picture]="'assets/image/profile.png'">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
