import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';


@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    config: any = { duration: (2 * 1000), direction: 'rtl', verticalPosition: 'bottom', horizontalPosition: 'center',
    };
    constructor(private snackBar: MatSnackBar) { }

    showSuccessMsg(msg: string) {
        this.snackBar.open(msg, 'باشه', this.config);
    }

    showErrorMsg(msg: string) {
        this.snackBar.open(msg, 'باشه', this.config);
    }

    showWarningMsg(msg: string) {
        this.snackBar.open(msg, 'باشه', this.config);
    }

}
