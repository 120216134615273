import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatrialComponentModule} from './matrial-component.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatrialComponentModule
  ],
  exports: [MatrialComponentModule]
})
export class SharedModule {
}
