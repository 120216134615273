import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';

@Injectable()
export class SmartTableService extends SmartTableData {

  data = [{
    id: 1,
    firstName: 'microsoft',
    lastName: '2500',
    username: '50',
  }, {
    id: 2,
    firstName: 'WordPress',
    lastName: '130',
    username: '40',

  }, {
    id: 3,
    firstName: 'IBM',
    lastName: '300',
    username: '40',
  }, {
    id: 4,
    firstName: 'Adobe',
    lastName: '3000',
    username: '68',
  },
    {
      id: 5,
      firstName: 'google',
      lastName: '400',
      username: '2',
    },
    {
      id: 6,
      firstName: 'intel',
      lastName: '40',
      username: '3',
    },
    {
      id: 7,
      firstName: 'ADM',
      lastName: '600',
      username: '300',
    },
    {
      id: 8,
      firstName: 'DELL',
      lastName: '50',
      username: '300',
    },
    {
      id: 9,
      firstName: 'LINUX',
      lastName: '552',
      username: '300',
    },
    {
      id: 9,
      firstName: 'QNAP',
      lastName: '34',
      username: '23',
    },
    {
      id: 9,
      firstName: 'Mozila',
      lastName: '2424',
      username: '44',
    },
    {
      id: 9,
      firstName: 'anyDesk',
      lastName: '20',
      username: '300',
    },
    {
      id: 9,
      firstName: 'PostgreSQL',
      lastName: '400',
      username: '300',
    },
    {
      id: 9,
      firstName: 'paloAlt',
      lastName: '410',
      username: '300',
    },
    {
      id: 9,
      firstName: 'D-lINK',
      lastName: '440',
      username: '3200',
    }
  ];

  getData() {
    return this.data;
  }
}
