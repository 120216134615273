// @ts-ignore
import {Component} from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="footer">
      <p class="footer_text"> لرستان، خرم آباد، دانشگاه لرستان، پارک علم و فناوری لرستان، شرکت داده پردازان ویرا رایان تلفن تماس:
        33402540-066 </p>
      <p class="text-center footer_text"> کلیه حقوق مادی و معنوی متعلق به شرکت داده پردازان ویرا رایان می باشد.</p>
    </div>

  `,
})
export class FooterComponent {
}
