import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import * as moment from "moment";

var today = new Date();

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor() {
    console.log("njfjrfkrf0000000000",this.date)

  }

  public date = {
    from: moment(new Date(today.getFullYear(), today.getMonth()-1, today.getDate()-1)).format('YYYY-MM-DD hh:mm:ss'),
    to: moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
  }

  // public date = {
  //   from: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate()-1)).format('YYYY-MM-DD hh:mm:ss'),
  //   to: moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DD hh:mm:ss'),
  // }
  // public date = {};
  private dateSource = new BehaviorSubject(this.date);
  currentDate = this.dateSource.asObservable();

  changeMessageDate(message: any) {
    this.dateSource.next(message);

  }

}
