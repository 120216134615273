import {Injectable} from '@angular/core';
// import * as moment from 'jalali-moment';
import {DashboardService} from "./dashboard.service";
import * as moment from 'moment';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public userData: any;
  public userToken: any;
  public userJson: any;
  public userType: any;
  public reportData: any;
  public year: any;
  public month: any;
  public dataTime: any;
  public brandList;
  public cweList;
  public sourceList;
  public published_date_start;
  public published_date_end;


  itemValue = new BehaviorSubject(this.getYear());
  constructor(private service: DashboardService) {
    this.getBrand();
    this.getCwe();
    this.getSource();

  }

  getInitialDate() {
    this.year = moment(new Date()).format('YYYY');
    this.month = moment(new Date()).format('MM');
  }





  getBrand() {
    let Token = localStorage.getItem('token');
    this.service.getAllBrand(Token).subscribe((response) => {
      if (response['success'] === true) {
        localStorage.setItem('brandList', JSON.stringify(response['data']));
      }
    })
  }
  getCwe() {
    let Token = localStorage.getItem('token');
    this.service.getAllCWE(Token).subscribe((response) => {
      if (response['success'] === true) {
        localStorage.setItem('cweList', JSON.stringify(response['data']));
      }
    })
  }
  getSource() {
    let Token = localStorage.getItem('token');
    this.service.getAllSource(Token).subscribe((response) => {
      if (response['success'] === true) {
        localStorage.setItem('source', JSON.stringify(response['data']));
      }
    })
  }
  getStorageSourcedList(): boolean {
    const result = localStorage.getItem('source');
    if (result !== undefined && result !== null) {
      this.sourceList = (result);
      return true;
    } else {
      return false;
    }
  }

  getStorageBrandList(): boolean {
    const result = localStorage.getItem('brandList');
    if (result !== undefined && result !== null) {
      this.brandList = (result);
      return true;
    } else {
      return false;
    }
  }

  getStorageCweList(): boolean {
    const result = localStorage.getItem('cweList');
    console.log('result=',result);
    if (result !== undefined && result !== null) {
      this.cweList = (result);
      return true;
    } else {
      return false;
    }
  }
  savePublished_date_Start(year: any) {
    localStorage.setItem('dateStart', year);
  }
  savePublished_date_End(year: any) {
    localStorage.setItem('dateEnd', year);
  }
  saveYear(year: any) {
    localStorage.setItem('year', year);
  }
  saveMonth(month: any) {
    localStorage.setItem('month', month);
  }
  saveToken(token: any) {
    localStorage.setItem('token', token);
  }
  getYear() {
    // localStorage.removeItem('year');
    const result = localStorage.getItem('year');
    if (result !== undefined && result !== null) {
      this.year = result;
      return true;
    } else {
      return false;
    }
  }
  getToken() {
    // localStorage.removeItem('year');
    const result = localStorage.getItem('token');
    if (result !== undefined && result !== null) {
      this.userToken = result;
      return true;
    } else {
      return false;
    }
  }
  getPublished_date_Start() {
    // localStorage.removeItem('year');
    let result = localStorage.getItem('dateStart');
    if (result !== undefined && result !== null) {
      this.published_date_start = result;
      return true;
    } else {
      return false;
    }
  }
  getPublished_date_End() {
    // localStorage.removeItem('year');
    let result = localStorage.getItem('dateEnd');
    if (result !== undefined && result !== null) {
      this.published_date_end = result;
      return true;
    } else {
      return false;
    }
  }
  getMonth() {
    // localStorage.removeItem('month');
    let result = localStorage.getItem('month');
    if (result !== undefined && result !== null) {
      this.month = result;
      return true;
    } else {
      return false;
    }
  }
  saveCurrentUser(data: any) {
    localStorage.setItem('currentUser', data);
  }

  saveReportData(data: any) {
    // localStorage.removeItem('report');
    localStorage.setItem('allCve', data);
  }

  getReportData(): boolean {
    let result = localStorage.getItem('allCve');
    if (result !== undefined && result !== null) {
      this.reportData = result;
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser(): boolean {
    this.userData = localStorage.getItem('currentUser');
    this.userJson = JSON.parse(this.userData);
    if (this.userData !== undefined && this.userData !== null) {
      this.userJson = JSON.parse(this.userData);
      this.userType = this.userJson['type'];
      return true;
    } else {
      return false;
    }
  }

  removeCurrentUser(): void {
    // alert('del');
    localStorage.removeItem('report');

  }
}
